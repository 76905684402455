.legendswrapper {
  display: flex;
  bottom: 0;
  flex-direction: row-reverse;
  justify-content: space-around;
  margin: 2rem 0 1rem 0;
}

.legendswrapper p {
  font-size: 0.8em;
  margin-bottom: 0.6rem;
}

.legendswrapper .desc {
  padding-left: 0.5rem;
}

.legendswrapper p:not(.desc) {
  text-align: right;
}

.icon {
  border-radius: 50px;
  height: 20px;
  width: 20px;
  margin-bottom: 2px;
  margin-right: 6px;
  left: 3.4rem;
}

.iconwrapper {
  display: flex;
}

.darkred {
  background: #c00000;
}

.mediumred {
  background: #e37548;
}

.red {
  background: #f5c07a;
}

.yellow {
  background: #e7e696;
}

.green {
  background: #c1df86;
}

.mediumgreen {
  background: #69ae65;
}

.darkgreen {
  background: #385724;
}
