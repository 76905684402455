.iconwrapper {
    display: flex;
    flex: 0 0 30%;
}

.iconwrapper img {
    margin: 0;
}

.votewrapper {
    display: flex;
    flex-direction: row;
    flex: 0 0 70%;
    justify-content: space-evenly;
    /*flex-wrap: wrap;*/
    padding: 0 0.6rem;
    align-items: center;
}

.votewrapper label:hover {
    cursor: pointer;
}

.votewrapper label {
    display: flex;
    -webkit-user-select: none; /* Chrome all / Safari all */
    -moz-user-select: none; /* Firefox all */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Likely future */
}

.votewrapper p {
    margin: 0;
    white-space: nowrap;
}

.votewrapper label p {
    margin-right: 0.6rem;
}

.votewrapper input {
    height: 1.4rem;
}

.votecard {
    display: flex;
    flex: 0 0 32%;
    margin: 1rem 0;
}

.decisionbox {
    box-shadow: none !important;
}
