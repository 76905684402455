.row {
    display: flex;
}

.title {
    font-style: italic;
}

.coltitles {
    display: flex;
    margin-left: 35px;
}

.coltitles > button,
.rows > button,
.rowtitles > button {
    margin: 0;
    padding: 0;
    width: 35px;
    height: 35px;
    border: none;
    background-color: transparent;
}

.coltitles > p {
    width: 35px;
    transform: rotate(35deg);
    white-space: nowrap;
    margin-bottom: 1rem;
}

.rows {
    display: flex;
    flex-direction: column;
}

.coltitles,
.rows {
    font-size: 0.6rem;
}

.rows > p {
    height: 35px;
    margin-bottom: 0;
}

.cell {
    text-align: center;
    width: 35px;
    height: 35px;
}

.rowmatrix {
    display: flex;
}

@media (max-width: 1500px) {
    .coltitles {
        margin-left: 30px;
    }

    .coltitles > button,
    .rows > button,
    .rowtitles > button {
        width: 30px;
        height: 30px;
    }

    .coltitles > p {
        width: 30px;
    }

    .rows > p {
        height: 30px;
    }

    .cell {
        width: 30px;
        height: 30px;
    }
}
