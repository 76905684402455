.backdrop {
   /*background: linear-gradient(0deg, rgb(1, 32, 64) 0%, rgb(90, 220, 232) 100%);*/
  background: radial-gradient(circle, rgb(114, 242, 255), rgb(0, 45, 90));
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}

@media (max-height: 940px) {
  .backdrop {
    position: relative;
  }
}

.container {
  background: #fff;
  width: 440px;
  padding: 1rem 0.6em;
  /* display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center; */
  align-items: center;
  font-weight: 900;
  overflow: scroll;
  touch-action: manipulation;
  padding-bottom: 6em;
}

.smallcontainer {
  background: #fff;
  width: 440px;
  padding: 1rem 0.6em;
}

.active {
  background: #333 !important;
  border-bottom: 2px solid #7c7c7c !important;
  text-decoration: none !important;
  color: #fff !important;
}

@media (max-width: 440px) {
  .container {
    width: 100%;
  }
}

@media (max-width: 322px) {
  .container {
    padding: 1rem 0.1em 6em 0.1em;
  }
}

.console {
  /* max-width: 800px; */
  background: #fff;
  display: inline-block;
  padding: 1em;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  width: 100%;
  min-height: 100vh;
}

.mediumconsole {
  background: #fff;
  display: flex;
  padding: 1rem;
  max-width: 80%;
  max-height: 80%;
  border-radius: 5px;
}

.smallconsole {
  background: #fff;
  display: inline-block;
  padding: 1em;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  max-width: 480px;
}

.console label,
.smallconsole label {
  margin-right: 0.6em;
  color: #7c7c7c;
}

.console input[type="text"],
.console textarea,
.smallconsole input[type="text"],
.smallconsole textarea {
  border: none;
  border-bottom: 1px solid #b5b5b5;
  padding: 0.3em;
}

.console textarea,
.smallconsole textarea {
  font-size: 0.9em;
  font-style: italic;
}

.console button[type="submit"],
.mediumconsole button[type="submit"],
.smallconsole button[type="submit"] {
  margin-top: 1em;
  background-color: #239c54;
  color: #fff;
  padding: 0.3em 2em;
  font-size: 1em;
  border: 6px solid #fff;
}

.console button[type="submit"]:hover,
.mediumconsole button[type="submit"]:hover,
.smallconsole button[type="submit"]:hover {
  background-color: #00643a;
}

.console footer,
.smallconsole footer {
  display: flex;
  justify-content: flex-end;
}

.votelist {
  list-style: none;
}

.squarebtn {
  background: none;
  cursor: pointer;
  border: 3px solid #757575;
  padding: 1em;
  width: 40%;
  margin: 0px auto;
}

.bar,
.graph {
  width: 100%;
  height: 700px;
  background: #f9f9f9;
}

.bar g text,
.graph g text {
  font-size: 16px !important;
  font-weight: bold;
}

@media (max-width: 700px) {
  .bar {
    width: 112%;
    margin-left: -2em;
  }
}

@media (max-width: 468px) {
  .bar {
    width: 116%;
  }
  .smallcontainer {
    width: 100%;
  }
  .console > h1 {
    margin-bottom: 0;
  }
}

.submitbtn {
  position: fixed;
  right: 4.9%;
  background-color: #239c54;
  color: #fff;
  padding: 0.3em 2em;
  font-size: 1.6em;
  bottom: 0;
  border: 6px solid #fff;
  border-bottom: 12px solid #fff;
}

.switchbtns {
    background: #efefef;
}

.switchbtns button {
  width: 25%;
  border: none;
  background: transparent;
  border: 2px solid transparent;
  border-right: 2px solid #7c7c7c;
}

.switchbtns button:hover {
  cursor: pointer;
  color: #333;
  text-decoration: none !important;
}

.switchbtns button:focus {
  outline: none;
  text-decoration: underline;
}

.switchbtns button:last-child {
  border-right: none;
}
