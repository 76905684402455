.goalwrapper {
    background: #333;
    /* display: flex;
          display: -webkit-flex; */
    margin-bottom: 3px;
    width: 100%;
    /* height: 100px; */
}

.goalwrapper img {
    margin-bottom: 0;
}

.btnwrapper {
    font-size: 2.5em;
    width: 75%;
    float: right;
    height: 100px;
    margin: 0;
    /* display: flex; */
    /* flex-grow: 1; */
    /* justify-content: center; */
    /* align-items: center; */
}

.imgwrapper {
    /* width: 24%; */
    display: inline-block;
    margin: 0;
    /* position: absolute; */
}

.btnwrapper:first-child {
    padding-left: 1.2em;
}

.btn {
    border: none;
    /* padding: 0 30px 0 0; */
    background-color: transparent;
    color: #fff;
    height: 100%;
    width: 25%;
    z-index: 999;
}

.btn:focus {
    outline: none;
}

.valuewrapper {
    width: 50%;
    color: #fff;
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    padding-left: 0.6em;
    text-align: center;
    display: inline-block;
}

.valuewrapper > span {
    margin-right: -0.5em;
    margin-bottom: 6px;
    display: inline-block;
    width: 17%;
}

.goalinput {
    pointer-events: none;
    padding: 0.3em;
    border: none;
    text-align: center;
    color: #fff;
    background: transparent;
    width: 80%;
    display: inline-block;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

input[type=number] {
    -moz-appearance: textfield;
}

.noimpactwrapper {
    clear: both;
    text-align: center;
    color: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0.6em 1em;
    display: none;
}

.noimpactwrapper span {
    margin-right: 0.6em;
}

@media (max-width: 430px) {
    .btnwrapper {
        font-size: 1.5em;
    }

    /* .goalwrapper {
                  height: 60px;
          } */
    .btnwrapper {
        width: 78%;
        height: 60px;
    }

    .goalwrapper img {
        height: 60px;
        width: 60px;
    }

    /* .imgwrapper {
                  width: 19%;
          } */
    .valuewrapper > span {
        width: 3%;
    }
}

@media (max-width: 300px) {
    .btnwrapper {
        width: 70%;
    }

    .valuewrapper > span {
        width: 17%;
    }
}

@media (max-width: 220px) {
    .btnwrapper {
        width: 60%;
        font-size: 1em;
    }
}
