.col {
  display: flex;
  align-items: flex-start;
  margin-left: 0.3rem;
  padding-top: 35px;
  justify-content: center;
}

.row {
  height: 33px;
  width: 50px;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #efefef;
  margin: 2px 0;
}

.bar {
  height: 30px;
}

.positive {
  background: #4db15d;
}

.negative {
  background: #f2703c;
}

.negativerow {
  justify-content: flex-end;
}

.imgrow {
  background: white;
  width:33px;
  text-align: center;
}

@media (max-width: 1500px) {
  .col {
    padding-top: 30px;
  }

  .row {
    height: 28px;
  }

  .bar {
    height: 25px;
  }
}
