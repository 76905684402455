.container {
    background: #fff;
}

.form {
    display: flex;
    flex-direction: column;
}

.submit {
    margin-top: 1rem;
    background-color: #239c54;
    color: #fff;
    padding: 0.3em 2em;
    font-size: 1.6em;
    bottom: 0;
    border: 6px solid #fff;
    border-bottom: 12px solid #fff;
}
