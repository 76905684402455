.goalinfo {
    position: absolute;
    padding: 0.6em 1em;
    background: #333;
    color: #fff;
    margin-top: 33px;
    margin-left: 33px;
    max-width: 400px;
    min-width: 300px;
    z-index: 99;
    font-size: 0.8rem;
    display: flex;
}

.goalinfo h1 {
    font-size: 1.2rem;
}

.goalinfo img {
    display: inline-block;
    /*width: 10%;*/
    bottom: 12px;
    left: 16px;
    width: 35px !important;
    height: 35px !important;
}

.goalinfo p {
    margin-bottom: 4em;
}

.icon {
    max-width: 100%;
}

.icon:hover {
    border: 2px solid #fff;
}

@media (max-width: 1500px) {
    .goalinfo {
        margin-top: 28px;
        margin-left: 28px;
    }

    .goalinfo img {
        width: 30px !important;
        height: 30px !important;
    }
}
