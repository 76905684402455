:root {
    --sdgswheel-radius: 335px;
}

.bar {
    stroke: #333;
    stroke-width: 5;
    fill-opacity: 1;
    stroke-opacity: 0.9;
}

.barholder {
    transform-origin: 50% 50%;
}

.wheel {
    width: 900px;
    height: 900px;
    border-radius: 50%;
    border: 2px dashed #dcdcdc;
    display: flex;
    justify-content: center;
    align-items: center;
    /*position: absolute;*/
    /*top: 0.3rem;*/
    /*margin-bottom: 50px;*/
}

.info {
    /*position: absolute;*/
    /*top: 1rem;*/
    /*left: 1rem;*/
}

.info h1 {
    margin-bottom: 0.6em;
}

.twoline,
.oneline,
.zeroline,
.minusline,
.minustwoline {
    width: 95.9%;
    height: 95.9%;
    border-radius: 50%;
    border: 1px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed #dcdcdc;
}

.minusthreeline {
    border-radius: 50%;
}

/* .oneline,
.minusline {
	border: 2px dotted;
}

.twoline,
.minustwoline {
	border: 2px dotted;
} */

.zeroline {
    border: 4px solid #333;
    z-index: 99;
}

.sdgswheel {
    width: calc(var(--sdgswheel-radius) * 2);
    height: calc(var(--sdgswheel-radius) * 2);
    border-radius: 50%;
    display: flex;
    overflow: hidden;
    position: relative;
}

.sdg {
    width: 50px;
    height: 150px;
    background: red;
    border: 1px solid;
    transform-origin: 50% 100%;
}

.sdgwrap {
    position: absolute;
    height: var(--sdgswheel-radius);
    width: var(--sdgswheel-radius);
    overflow: hidden;
    transform-origin: 100% 100%;
    border: 10px solid #fff;
    border-top: none;
}

.sdgwrap > div {
    position: absolute;
    right: 0.8rem;
    bottom: 8rem;
    font-size: 1.6rem;
    color: #fff;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 54%;
}

.sdg1wrapper {
    position: absolute;
    height: var(--sdgswheel-radius);
    width: var(--sdgswheel-radius);
    overflow: hidden;
    transform: rotate(90deg);
    transform-origin: 100% 100%;
    z-index: 17;
    pointer-events: none;
}

.sdg2wrapper {
    position: absolute;
    height: var(--sdgswheel-radius);
    width: var(--sdgswheel-radius);
    overflow: hidden;
    transform: rotate(111deg);
    transform-origin: 100% 100%;
    z-index: 17;
    pointer-events: none;
}

.sdg3wrapper {
    position: absolute;
    height: var(--sdgswheel-radius);
    width: var(--sdgswheel-radius);
    overflow: hidden;
    transform: rotate(132deg);
    transform-origin: 100% 100%;
    z-index: 17;
    pointer-events: none;
}

.sdg4wrapper {
    position: absolute;
    height: var(--sdgswheel-radius);
    width: var(--sdgswheel-radius);
    overflow: hidden;
    transform: rotate(153deg);
    transform-origin: 100% 100%;
    z-index: 17;
    pointer-events: none;
}

.wheelmiddle {
    background: #fff;
    width: 50%;
    height: 50%;
    z-index: 99;
    position: absolute;
    left: 25%;
    top: 25%;
    border-radius: 50%;
}

.console {
    display: flex;
    justify-content: center;
    align-items: center;
}

.console h4 {
    margin-bottom: 1rem;
}

.console h1 {
    font-size: 1.4rem;
}

.votecolorswitchwrapper {
    font-size: 1.4rem;
    /*position: absolute;*/
    /*top: 1rem;*/
    /*right: 1rem;*/
}

.votecolorswitchwrapper input {
    margin-right: 1rem;
}

.averagecircle {
    background: #fff;
    width: 40px;
    height: 40px;
    font-size: 1rem;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-top: -4px;
    margin-left: 0.1rem;
    margin-bottom: 15px;
}

.icon {
    width: 60px;
    height: 60px;
    z-index: 9999;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.666rem;
}

.sdgtitlewrapper {
    position: fixed;
    color: #fff;
    z-index: 999;
    background: #333;
    padding: 0.3rem 0.6rem;
    border-radius: 4px;
    border: 2px solid #fff;
}

.sdgtitlewrapper h2 {
    font-size: 1.4rem;
    max-width: 20rem;
    line-height: 1.9rem;
    margin-bottom: 0.3rem;
}

.votelegends {
    /*position: absolute;*/
    /*top: 20%;*/
    /*right: 2rem;*/
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.footer {
    position: fixed;
    bottom: 0;
    right: 0;
}

.footer a {
    margin: 1rem;
    padding: 0.6rem 1rem;
    background-color: #239c54;
    color: #fff;
    padding: 0.3em 2em;
    font-size: 1.6em;
    bottom: 0;
    border: 6px solid #fff;
    border-bottom: 12px solid #fff;
}
