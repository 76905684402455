.strategy-list-item {
  padding: 0;
}

.strategy-list-item .row {
  margin: 0;
}

.strategy-list-item a button {
  min-height: 4rem;
}

.strategy-list-item .col-12 button {
  max-width: 200px;
}

.strategy-list-item .col-12 button.btn-success {
  max-width: 100px;
}

.strategy-list-item hr {
  border: 1px solid #efefef;
  width: 100%;
}

.wheelLogoInStrategy {
  max-height: 240px;
}
