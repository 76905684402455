.submitButton {
    width: 100%;
    background: rgb(127, 185, 9) !important;
    color: #fff;
    border: 2px solid #e8e8e8;
    border-radius: 0;
}

.submitButton:hover {
    background: rgb(107, 165, 9) !important;
    border: 2px solid #e8e8e8;
}

.clearButton {
    width: 100%;
    background: #fff;
    color: #333;
    border: 2px solid #e8e8e8;
    border-radius: 0;
}

.clearButton:hover {
    color: #333;
    background: #f3f3f3;
    border: 2px solid #e8e8e8;
}