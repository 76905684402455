.row {
  background: #efefef;
  display: flex;
  align-items: flex-end;
  height: 75px;
  justify-content: flex-start;
  margin-top: 0.3rem;
  width: 595px;
  margin-left: 87px;
  padding-left: 1px;
}

.bar {
  background: #4db15d;
  width: 35px;
  margin: 0 2px;
}

.negative {
  background: #f2703c;
}

.reverse {
  align-items: flex-start;
}

@media (max-width: 1500px) {
  .bar {
    width: 30px;
  }

  .row {
    width: 510px;
  }
}
