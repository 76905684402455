.context-pane {
  padding-right: 6px;
  padding-left: 6px;
}

.blob-filter-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.blob-filter-wrapper > div {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 0.3rem;
}

.blob-filter-wrapper > div > label {
  margin: 0;
  font-size: 2rem;
  width: 3rem;
}

.blob-filter-wrapper > div > input {
  line-height: 1rem;
  margin-top: 0.4rem;
}

.blob-slider-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.6rem;
}

.blob-slider-wrapper > label {
  text-align: right;
  white-space: nowrap;
  position: absolute;
  margin-right: 310px;
  width: 160px;
}

.blob-slider-values {
  display: flex;
  width: 160px;
  justify-content: space-between;
}

.blob-slider-values > span {
  padding: 0.3rem;
  display: flex;
  justify-content: space-around;
}

.indirect-wrapper {
  padding: 0px;
}

.indirect-wrapper > div {
  margin-left: 0;
}

@media (max-width: 1500px) {
  .indirect-wrapper {
    padding: 10px 10px 10px 13px;
  }
}

.breadcrumb {
  background-color: inherit;
}