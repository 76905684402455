.wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 1px solid #dadada;
}

.imgwrapper img {
    margin: 5px;
}

.votewrapper {
    display: flex;
    flex: 0 0 50%;
    justify-content: space-around;
    align-items: center;

}

.votewrapper p {
    white-space: nowrap;
    margin: 0;
}

.votewrapper input {
    height: 1.4rem;
}

.votewrapper label {
    font-weight: bolder
}

.checkbox {
    box-shadow: none !important;
}