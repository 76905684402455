.blob-matrix {
    border-collapse: collapse;
    border-spacing: 0;
    border: 0px dashed;
    border-top: 0;
    margin-bottom: 0;
}

.blob-matrix > tbody > tr {
    text-align: center;
    padding: 0;
}

.blob-matrix > tbody > tr > td {
    text-align: center;
    padding: 0;
    margin: 0;
    border: 0;
}

.blob-matrix > tbody > tr > td > img {
    max-height: 40px !important;
}

.blob-matrix > tbody > tr > td > svg {
    max-height: 40px !important;
}

.blobmatrix-point {
    stroke: #4d4d4d;
    stroke-width: 1;
    opacity: 0.3;
}

.plusPlus {
    background-color: #70ad47;
}

.plusMinusOrMinusPlus {
    background-color: #ffc000;
}

.minusPlus {
    background-color: #ff0000;
}

.minus3 {
    background-color: #801535;
}

.minus2 {
    background-color: #f2703c;
}

.minus1 {
    background-color: #FDBF6F;
}

.zero {
    background-color: #FFFFBF;
}

.plus1 {
    background-color: #B7E075;
}

.plus2 {
    background-color: #4DB15D; /*#ff5333;*/
}

.plus3 {
    background-color: #5DBB46;
}