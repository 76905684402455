.bar {
    background: #eb1c2e;
    width: 6px;
    margin-right: 1px;
}

.wheelbars {
    display: flex;
    position: absolute;
    transform-origin: 66px 449px;
    height: 113px;
    align-items: flex-end;
    width: 132px;
}

.positivebars {
    height: 53.7%;
    width: 100%;
    position: absolute;
    top: 0;
    display: flex;
    align-items: flex-end;
    overflow: hidden;
    justify-content: center;
    height: 55.5%;
}

.negativebars {
    height: 49%;
    width: 100%;
    position: absolute;
    top: 52%;
    display: flex;
    align-items: flex-start;
    overflow: hidden;
    justify-content: center;
}
